import {
  AppBar,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isManager } from '../../helpers/roles'
import { filesService } from '../../services/filesService'
import EndingImageExpander from './endingImageExpander'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  mainContainer: {
    padding: '1% 4%',
  },
  sectionTitle: {
    fontWeight: 'bold',
    padding: '0.3% 0',
  },
  infoText: {
    color: 'black',
    fontSize: 16,
  },
  header: {
    marginLeft: '0.1%',
  },
}))

const ServiceDetails = ({ onClose, isOpen, dataCustomer }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const handleClose = () => onClose()

  const INVALID_DATE = '0001-01-01T00:00:00Z'

  const [endingImage, setEndingImage] = useState(null)
  const [isEndingImageExpanded, setIsEndingImageExpanded] = useState(false)

  useEffect(() => {
    if (isOpen) {
      filesService.get(dataCustomer.endingImageId).then((endingImage) => {
        setEndingImage(endingImage)
      })
    }
  }, [isOpen])

  function openEndingImageInNewTab() {
    window.open(endingImage, '_blank', 'noreferrer')
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.header}>
              {t('servicesPage.page.details.header')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div className={classes.mainContainer}>
          <Grid container spacing={2}>
            {isManager() && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('servicesPage.page.details.operatorName')}
                  InputProps={{ readOnly: true }}
                  value={dataCustomer.operatorName}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.serviceId')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.serviceId}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.plate')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.vehiclePlate}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.vin')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.vehicleVin}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.vehicleName')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.vehicleName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.customerName')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.customerFullName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.email')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.customerEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.totalPrice')}
                InputProps={{ readOnly: true }}
                value={`${dataCustomer.price} €`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.paymentStatus')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.paymentStatus}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t('servicesPage.page.details.reserveSection')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.start')}
                InputProps={{ readOnly: true }}
                // value={`${dataCustomer.reserveStartTimestamp}`.split("T")[0]}
                value={moment(dataCustomer.reserveStartTimestamp).format(
                  'DD/MM/YYYY',
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.cancel')}
                InputProps={{ readOnly: true }}
                value={
                  dataCustomer.reserveCancelTimestamp === INVALID_DATE
                    ? ''
                    : moment(dataCustomer.reserveCancelTimestamp).format(
                        'DD/MM/YYYY',
                      )
                }
              />
            </Grid>
            <Grid container item spacing={1} xs={6}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  {t('servicesPage.page.details.daySection')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('servicesPage.page.details.start')}
                  InputProps={{ readOnly: true }}
                  value={moment(dataCustomer.tripStartDay).format('DD/MM/YYYY')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('servicesPage.page.details.end')}
                  InputProps={{ readOnly: true }}
                  value={moment(dataCustomer.tripEndDay).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} xs={6}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  {t('servicesPage.page.details.hourSection')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('servicesPage.page.details.start')}
                  InputProps={{ readOnly: true }}
                  value={moment(dataCustomer.tripStartHour).format('HH:mm:ss')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('servicesPage.page.details.end')}
                  InputProps={{ readOnly: true }}
                  value={moment(dataCustomer.tripEndHour).format('HH:mm:ss')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t('servicesPage.page.details.minutesSection')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.minutesDriven')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.totalMinutes}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.minutesPaused')}
                InputProps={{ readOnly: true }}
                value={parseFloat(dataCustomer.pausedMinutes).toFixed(2)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t('servicesPage.page.details.batterySection')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.batteryUsed')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.batteryUsage}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.start')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.startBatterySoc}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.end')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.endBatterySoc}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.co2Saved')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.co2Saved}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t('servicesPage.page.details.speedSection')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.speedAvg')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.speedAverage}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('servicesPage.page.details.speedMax')}
                InputProps={{ readOnly: true }}
                value={dataCustomer.speedMax}
              />
            </Grid>
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  {t('servicesPage.page.details.endServiceImageSection')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {endingImage != null && endingImage.length > 0 ? (
                  <img
                    src={endingImage}
                    style={{
                      flex: 1,
                      objectFit: 'center',
                      width: '25%',
                    }}
                    onClick={openEndingImageInNewTab}
                  />
                ) : (
                  <Typography variant="body1">
                    {t('servicesPage.page.details.endServiceImageNotFound')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <EndingImageExpander
        isOpen={isEndingImageExpanded}
        endingImage={endingImage}
        onDismiss={() => setIsEndingImageExpanded(false)}
      />
    </div>
  )
}

export default ServiceDetails
