import stringConstants from '../constants/strings'
import { authHeader } from '../helpers/authHeader'

export const loginService = {
  token,
  userInfo,
  logout,
  refreshToken,
  getWithExpiry,
  setWithExpiry,
  getOperatorData,
}

function refreshToken(token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken: token }),
  }

  return fetch(
    stringConstants.URL_ApiGateway + '/users/v1/RefreshToken',
    requestOptions,
  )
    .then(handleResponse)
    .then((token) => {
      // store token details and jwt token in local storage to keep user logged in between page refreshes
      return token
    })
}

function token(email, password) {
  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Access-Control-Allow-Origin', '*')

  let raw = JSON.stringify({ email, password })

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }

  return fetch(
    `${stringConstants.URL_ApiGateway}/users/v2/Token`,
    requestOptions,
  )
    .then((response) => response.text())
    .then((result) => {
      const {
        idToken,
        refreshToken: refreshTokenProp,
        expiresIn,
      } = JSON.parse(result)
      var date = new Date()
      var now_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      )
      var now = new Date(now_utc)
      now.setSeconds(now.getSeconds() + expiresIn)

      setInterval(function () {
        refreshToken(refreshTokenProp)
      }, 3540000)

      setWithExpiry('sharing_token_dateExpiry', now, expiresIn)
      setWithExpiry('sharing_token', idToken, expiresIn)
      setWithExpiry('sharing_refresh_token', refreshTokenProp, 2592000)
      // store token details and jwt token in local storage to keep user logged in between page refreshes
      return JSON.parse(result)
    })
    .catch((error) => console.log('error token fetch: ', error))
}

function userInfo() {
  var requestOptions = {
    method: 'GET',
    headers: authHeader(),
    redirect: 'follow',
  }

  // return fetch(`${stringConstants.URL_ApiGateway}/sharing/v1/users/${token}`, requestOptions)
  return fetch(
    `${stringConstants.URL_ApiGateway}/sharing/v1/users/GetCurrent`,
    requestOptions,
  )
    .then(handleResponse)
    .then((user) => {
      setWithExpiry('sharing_user', user, 3600)
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      return user
    })
}

function getOperatorData(id) {
  var requestOptions = {
    method: 'GET',
    headers: authHeader(),
    redirect: 'follow',
  }

  return fetch(
    `${stringConstants.URL_ApiGateway}/operators/v1/Operators/${id}`,
    requestOptions,
  ).then(handleResponse)
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('sharing_user')
  localStorage.removeItem('sharing_token')
}

function handleResponse(response) {
  //response.text().then(res => console.log('handleResponse res',res))
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || text
      return Promise.reject(error)
    }
    return data
  })
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  var date = new Date()
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
  const now = new Date(now_utc)
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

function setWithExpiry(key, value, ttl) {
  var date = new Date()
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
  const now = new Date(now_utc)
  ttl = ttl * 1000
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}
